import { SitemapIcon } from '@panwds/icons';

import {
    SPARKY_NAV3_LEFT_NAV_EXTPOINT,
    SPARKY_NAV3_ROUTES_EXTPOINT,
    SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
    SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
} from '@sparky/framework/extpoints';
import { fetchAndSetTenantInfo } from './fetchAndSetTenantInfo';
import { fetchMicroAppLicense } from './fetchMicroAppLicense';
import { getState, getMicroAppVars } from '@sparky/framework';
import { buildProps } from './utils/buildProps';
import { extractFeatures } from './utils/extractFeatures';
import { getMFEPropertyAdemLicense } from './utils/mfeProperty';
import { APIManagerInstance, featureKeyFormatter, isNil, MICRO_APP_ADEM, setMicroAppLicenseInfo, setMicroAppTenantInfo } from '@saseia/ui';

const INSTANCE_STATUS_RUNNING = 'running';

export const extensions = ({ sparkyFramework, vars }) => {
    console.log('extensions in unified app - ');
    sparkyFramework.actions.setValues({ saseIaGlobalState: {} });

    const frameworkVars = sparkyFramework.getFrameworkVars();
    const { private_site_origin } = frameworkVars;
    const isPrivateApp = Boolean(private_site_origin);

    // TODO: to get context on this object
    const stateExt = {
        // declare ext for api calls (please avoid, and using MFE Propery instead)
        point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
        configs: [
            {
                id: 'netsec.sase_ia.tsg.state.example',
                config: {
                    name: 'sase_ia', // this we can use using getState().unified
                    requireFetch: true, // true for use the fetch function
                    // loader add the logic for profile and permissions
                    loader: async (props) => {
                        const auth = props.state.auth;
                        const main = props.state.main;

                        // Set license for micro apps
                        const sdwanInstance = auth?.instances?.get('cgx');

                        // The following function will take care of three things
                        // 1. Instantiate the initial values required in the API/Network Manager Singletons
                        // 2. Set initial values for saseIaGlobalState
                        // 3. Set the tenancy information for both GCS vs Customer App
                        const { providerProps } = buildProps({ main, auth });

                        /**
                        * AIOPS-11833 - Call cyr_env API and assign to saseIaCyrEnv state for Tenant 360
                        * Only call if GCS env and GCS TSG Support is enabled
                        */
                        if(isNil(getState()?.saseIaGlobalState?.cyr_env) && isPrivateApp && gcsTsgEnabled){
                            const tsgId = auth?.tsg_id;
                            const subtenant = getState()?.saseIaGlobalState?.subtenant;

                            if(!isNil(tsgId)) {
                                APIManagerInstance.fetchCYREnvForTSG(tsgId, subtenant ?? "")
                                    .then((data) => {
                                        sparkyFramework.actions.setValues({ saseIaCyrEnv: data?.data?.[0]?.cyr_env });
                                    });
                            }
                        }
                        const saseIAFeatures = extractFeatures(auth);
                        const { saseIAMainState } = sparkyFramework.getState();
                        const featureFlags = getMicroAppVars('pai')?.featureFlags;
                        const gcsTsgEnabled = !isNil(featureFlags) ? JSON.parse(featureFlags).gcs_tsg_enabled : false;
                        console.log('Loader was called - ', saseIAFeatures, saseIAMainState);
                        fetchAndSetTenantInfo({ sparkyFramework, auth, isPrivateApp, providerProps });
                        if (saseIAMainState?.cosmosFeatures?.length > 0) {
                            /* Do nothing as `cosmosFeatures` is already set */
                        } else if (saseIAFeatures?.length > 0) {
                            /*
                                If cosmosFeatures is not set but Sparky MFE is set, then set the cosmosFeatures and return
                                If saseIAFeatures has missing MFE feature data, fallback to the feature API
                            */
                            sparkyFramework.actions.setValues({ saseIAMainState: { cosmosFeatures: saseIAFeatures } });
                        } else {
                            // Make the API call to get the features
                            // await write to sparky state before sending navigation (left hand nav)
                            await sparkyFramework
                                .dedupPromise(
                                    'navigationSync',
                                    () =>
                                        new Promise(async (resolve, reject) => {
                                            try {
                                                const tsgId = auth?.tsg_id;
                                                const { email } = auth?.user ?? {};
                                                const { initialState } = providerProps;
                                                const { serverFqdns } = initialState;
                                                const { gc_fqdn } = serverFqdns ?? {
                                                    gc_fqdn: getFrameworkServiceUrl('cosmos'),
                                                };
                                                /**
                                                 * Fetch subtenant from saseIaGlobalState which fetches in the following order
                                                 * 1. MFE -> 2. subtenant API (1 and 2 are set in saseIaGlobalState)
                                                 * -> 3. auth.instances.....tenant_id -> 4. null
                                                 * @type subtenant - string
                                                 */
                                                const subtenant =
                                                    getState()?.saseIaGlobalState?.subtenant ??
                                                    getState()?.auth?.instances?.find(
                                                        (index) => index?.app_id === 'logging_service'
                                                    )?.tenant_id ??
                                                    null;

                                                const promise = gcsTsgEnabled
                                                    ? sparkyFramework.dedupPromise(
                                                        featureKeyFormatter(tsgId),
                                                        () => APIManagerInstance.fetchFeatures(tsgId, subtenant),
                                                        { keepPromiseIn: -1 }
                                                    )
                                                    : isPrivateApp
                                                    ? sparkyFramework.dedupPromise(
                                                        `cosmosRole-gcsInit`,
                                                        () => APIManagerInstance.fetchUserFeatures(email, gc_fqdn),
                                                        { keepPromiseIn: 60000 }
                                                    )
                                                    : sparkyFramework.dedupPromise(
                                                        featureKeyFormatter(tsgId),
                                                        () => APIManagerInstance.fetchFeatures(tsgId, tsgId),
                                                        { keepPromiseIn: -1 }
                                                    );
                                                const result = await promise;

                                                sparkyFramework.actions.setValues({
                                                    saseIAMainState: { cosmosFeatures: result?.data },
                                                });
                                                resolve(result?.data);
                                            } catch (e) {
                                                reject(e);
                                            }
                                        }),
                                    { keepPromiseIn: 60000 }
                                )
                                .then((res) => {
                                    sparkyFramework.actions.setValues({
                                        saseIAMainState: { cosmosFeatures: res },
                                    });
                                });
                        }

                        let ademLicense;
                        // Initialize dashboards and fetch micro app license info
                        if (!isPrivateApp) {
                            ademLicense = getMFEPropertyAdemLicense(auth);
                            if (!ademLicense) {
                                ademLicense = await fetchMicroAppLicense();
                            }

                            if (ademLicense?.licenseMu || ademLicense?.licenseRnenseMu) {
                                setMicroAppLicenseInfo(MICRO_APP_ADEM, {
                                    licenseMu: ademLicense?.licenseMu,
                                    licenseRn: ademLicense?.licenseRn,
                                });
                                setMicroAppTenantInfo(MICRO_APP_ADEM, ademLicense);
                            }
                        }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       

                        const licenseInfo = {
                            isSDWANInstRunning: isPrivateApp
                                ? false
                                : sdwanInstance?.status === INSTANCE_STATUS_RUNNING,
                            isPAInstRunning: isPrivateApp ? true : auth?.instances?.isFawkesRunning,
                            isAdemNgfwRnRunning: ademLicense?.ademLicenses?.ngfw?.rn
                        };

                        const isADEMTier1Role = auth?.access?.hasRole('adem_tier_1_support');
                        const sparkyState = sparkyFramework?.getState();
                        const SDWANRBACBranch = sparkyState?.sdwan_rbac?.monitor?.branchSites;
                        const SDWANRBACDC = sparkyState?.sdwan_rbac?.monitor?.dataCenters;
                        const ADEMWithNGFWBranchSites = ademLicense?.ademLicenses?.ngfw?.rn && cosmosFeatures?.findIndex(feature => feature.feature === "panw_netsec:ngfw_adem_enabled") >= 0;

                        const hasBranchSites = () => {
                            const { saseIAMainState } = sparkyFramework.getState();
                            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
                            /**
                             * we write cosmosFeatures to state in either pai, unified app, or DEM deduped calls. It depends * on which microapp sparky calls first.  This will detect an issue between app code.
                             */
                            if (!cosmosFeatures) {
                                console.error(
                                    'cosmosFeatures is not defined. Left hand nav will not work as designed.'
                                );
                            }
                            return (
                                SDWANRBACBranch || ADEMWithNGFWBranchSites ||
                                cosmosFeatures?.findIndex((feature) => feature.feature === 'panw_sase:branch_sites') >=
                                    0
                            );
                        };

                        const hasDataCenter = () => {
                            const { saseIAMainState } = sparkyFramework.getState();
                            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
                            /**
                             * we write cosmosFeatures to state in either pai, unified app, or DEM deduped calls. It depends * on which microapp sparky calls first.  This will detect an issue between app code.
                             */
                            if (!cosmosFeatures) {
                                console.error(
                                    'cosmosFeatures is not defined. Left hand nav will not work as designed.'
                                );
                            }

                            return (
                                SDWANRBACDC ||
                                (!isADEMTier1Role &&
                                    cosmosFeatures?.findIndex(
                                        (feature) => feature.feature === 'panw_sase:data_center'
                                    ) >= 0)
                            );
                        };

                        const hasSubscriptionUsage = () => {
                            const { saseIAMainState } = sparkyFramework.getState();
                            const cosmosFeatures = saseIAMainState?.cosmosFeatures;
                            return (
                                licenseInfo.isSDWANInstRunning ||
                                cosmosFeatures?.findIndex(
                                    (feature) => feature.feature === 'panw_sase:subscription_usage'
                                ) >= 0
                            );
                        };

                        const isSASE = SDWANRBACBranch && licenseInfo.isSDWANInstRunning;
                        return { ademLicense, hasBranchSites, hasDataCenter, hasSubscriptionUsage, isSASE, licenseInfo };
                    },
                },
            },
        ],
    };

    const nav3LeftNavExt = {
        // declares paths
        point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
        configs: [
            {
                id: 'netsec.sase_ia.nav.config',
                stateLoaders: ['auth', 'sase_ia'],
                config: {
                    navigation: () => [
                        {
                            key: '/operational-insights',
                            title: 'Operational Insights',
                            icon: SitemapIcon,
                            contentClassName: 'monitor',
                            path: '/operational-insights',
                            children: [
                                {
                                    key: "prisma-sase",
                                    title: "Prisma Sase",
                                    contentClassName: "monitor",
                                    avail: () => true,
                                    children: [
                                        {
                                            key: "monitor-branch-sites",
                                            title: 'SASE Branch Sites',
                                            path: '/operational-insights/sase-branch-sites',
                                            contentClassName: 'panwds-tw3 monitor-branch-sites',
                                            avail: () => {
                                                return sparkyFramework.getState().sase_ia?.hasBranchSites();
                                            },
                                        },
                                        {
                                            key: 'monitor-data-centers',
                                            title: 'SASE Data Centers',
                                            contentClassName: 'panwds-tw3 monitor-data-centers',
                                            path: '/operational-insights/sase-data-centers',
                                            avail: () => {
                                                return sparkyFramework.getState().sase_ia?.hasDataCenter();
                                            },
                                        },
                                    ],
                                }
                            ]
                            
                        },
                        {
                            key: 'settings',
                            title: 'System Settings',
                            icon: SitemapIcon,
                            contentClassName: 'monitor',
                            path: '/settings',
                            children: [
                                {
                                key: "subscriptions",
                                    title: "Subscriptions",
                                    contentClassName: "monitor",
                                    avail: () => true,
                                    children:[
                                {
                                    key: 'monitor-subscription-usage',
                                    title: 'Subscription Usage',
                                    contentClassName: 'panwds-tw3 monitor-subscription-usage',
                                    path: '/settings/subscription-usage/subscriptionUsage',
                                    avail: () => {
                                        return sparkyFramework.getState().sase_ia?.hasSubscriptionUsage();
                                    },
                                },
                            ]
                            }
                            ],
                        },
                    ],
                },
                isAllowed: () => true,
            },
        ],
    };

    const nav3routes = [
        {
            id: 'netsec.sase_ia.routes.operational-insights.sase-branch-sites',
            config: {
                path: '/operational-insights/sase-branch-sites',
                contentClassName: 'panwds-tw3 monitor-branch-sites',
                component: () => import('./SASEIAUnifiedRoutes.js'),
                avail: () => {
                    return sparkyFramework.getState().sase_ia?.hasBranchSites();
                },
            },
        },
        {
            id: 'netsec.sase_ia.routes.operational-insights.sase-data-centers',
            config: {
                path: '/operational-insights/sase-data-centers',
                contentClassName: 'panwds-tw3 monitor-data-centers',
                component: () => import('./SASEIAUnifiedRoutes.js'),
                avail: () => {
                    return sparkyFramework.getState().sase_ia?.hasDataCenter();
                },
            },
        },
        {
            id: 'netsec.sase_ia.routes.monitor.subscription-usage.subscriptionUsage',
            config: {
                contentClassName: 'panwds-tw3 monitor-subscription-usage',
                path: '/settings/subscription-usage/subscriptionUsage',
                component: () => import('./SASEIAUnifiedRoutes.js'),
                avail: () => {
                    return sparkyFramework.getState().sase_ia?.hasSubscriptionUsage();
                },
            },
        },
    ];

    const nav3routeExt = {
        point: SPARKY_NAV3_ROUTES_EXTPOINT,
        configs: nav3routes.map((route, index) => ({
            ...route,
            config: {
                ...route.config,
                mapStateToProps: (props) => {
                    return({
                    ...props,
                    routes: [{ ...route }],
                    vars,
                    frameworkVars,
                    licenseInfo: props?.sase_ia?.licenseInfo,
                    isPrivateApp
                })},
            },
        })),
    };

    const exposesExt = {
        point: SPARKY_NAV3_TSG_EXPOSES_COMPAT_EXTPOINT,
        configs: [
            {
                id: 'netsec.sase_ia.exposes',
                config: {
                    exposes: () => ({
                        default: {
                            TenantFetcher: () => import('./TenantFetcherWrapper'),
                            TenantSelector: () => import('./TenantSelectorWrapper'),
                            SubTenantSelector: () => import('./SubTenantSelectorWrapper'),
                            GlobalSearch: () => import('./GlobalSearchWrapper'),
                        },
                    }),
                },
            },
        ],
    };
    console.log('return extensions in unified app - ');
    return [nav3LeftNavExt, nav3routeExt, stateExt, exposesExt];
};
